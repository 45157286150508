import React, { Component } from 'react';
import axios from 'axios';
import Logo from '../assets/img/Logo_91_social.png'
import Learning from '../assets/img/learning.png'
import Google from '../assets/img/google.png'
import './login.css';

class Login extends Component {

    async componentDidMount(){
        const res= await axios.get(`https://api.edtech.stg.91social.net:8443/api/welcome`);
        console.log(res);
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className=" float-left">
                        <img className="d-block w-100" src={Logo} alt="logo" />
                    </div>
                </div>
                <div className="row learning">
                    <div className="col-8">
                        <img className="d-block w-100" src={Learning} alt="logo" />
                    </div>
                    <div className="col-4 top">
                        <div className="right">
                            <div className="login">Log in</div>
                            <h6>with your Gsuite credentials</h6>
                            <div>
                                <button type="button" className="btn btn-light">
                                    <img className="d-block w-100 google" src={Google} alt="logo" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Login