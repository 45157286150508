import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './components/Login/login.js'

function App() {
  return (
    <Router>
        <div className="App">
          <div className="cointainer">
            <Switch>
              <Route exact path="/" component={Login} />
            </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
